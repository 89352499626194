import React, { useEffect, useLayoutEffect, useState } from "react";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import Button from "view/components/Button";
import TextInput from "view/components/InputField";
import ConnectedOrganizations from "./ConnectedOrganizations";
import { OrganizationList } from "./OrganizationList";
import { FormikProps, FormikValues, FieldArray } from "formik";
import { ProfileModal } from "./ProfileModal";
import apiLibrary from "services/api";
import Placeholder from "assets/images/Placeholders/avatar_communityProfile_placeholder.png";
import { TailSpin } from "react-loader-spinner";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import { Toasts } from "view/components/Toasts";
interface AddOrganizationProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const AddOrganization: React.FC<AddOrganizationProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  const [searchString, setSearchString] = useState("");
  const [entitiesList, setEntitiesList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const customSort = (entities: any): any => {
    return entities.sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const updateOrganizationIds = (values: any, entity: any) => {
    const organizationIds = values?.organizationIds ?? [];
    const isEntityAlreadyConnected = organizationIds.some(
      (item: any) => item.id === entity.id
    );

    if (!isEntityAlreadyConnected) {
      const updatedList = [...organizationIds, entity];
      return customSort(updatedList);
    }

    return organizationIds;
  };

  const addExistingEntity = (entity: any) => {
    setEntitiesList((prev) => prev.filter((item) => item.id !== entity.id));
    const updatedList = updateOrganizationIds(values, entity);
    if (searchString !== "") {
      searchOrganizations(1, "", updatedList);
    }
    setFieldValue("organizationIds", updatedList);
  };

  const removeExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) => {
      const isEntityPresent = prevEntitiesList.some(
        (item) => item.id === entity.id
      );

      if (!isEntityPresent) {
        const updatedEntitiesList = [...prevEntitiesList, entity];
        return customSort(updatedEntitiesList);
      } else {
        return prevEntitiesList;
      }
    });

    function updateConnectedEntitiesList() {
      const updatedConnectedEntitiesList = values?.organizationIds?.filter(
        (item: any) => item.id !== entity.id
      );

      return customSort(updatedConnectedEntitiesList);
    }

    const newList = updateConnectedEntitiesList();
    if (searchString !== "") {
      searchOrganizations(1, "", newList);
    }

    setFieldValue("organizationIds", newList);
  };
  const removeAllEntities = (): void => {
    setEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...values?.organizationIds?.filter(
          (connectedEntity: any) =>
            !prevEntitiesList.some((entity) => entity.id === connectedEntity.id)
        ),
      ];
      return customSort(updatedEntitiesList);
    });

    setFieldValue("organizationIds", []);
  };

  const addAllEntities = (): void => {
    const updatedOrganizationIds = () => {
      const updatedEntitiesList = [
        ...values?.organizationIds,
        ...entitiesList.filter(
          (newEntity) =>
            !values?.organizationIds?.some(
              (prevEntity: any) => prevEntity.id === newEntity.id
            )
        ),
      ];
      return customSort(updatedEntitiesList);
    };

    if (searchString !== "") {
      searchOrganizations(1, "", updatedOrganizationIds());
    }
    setFieldValue("organizationIds", updatedOrganizationIds());
    setEntitiesList([]);
  };

  const getAllUserWhichCanBeAddAsOrganization = async (
    isLoadMore: boolean,
    page: number,
    searchString: string
  ) => {
    if (!isLoadMore) {
      setIsLoading(true);
    }

    try {
      const { data } = await apiLibrary.Communities.getallOrganization(
        searchString,
        page,
        values?.appName?.value
      );

      const communityOrganizations = values.organizationIds ?? [];

      const entitieslist = getSortedDifference(
        data.organizations,
        isLoadMore ? values.organizationIds : communityOrganizations
      );

      setEntitiesList(
        isLoadMore ? (prevList) => [...prevList, ...entitieslist] : entitieslist
      );

      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserWhichCanBeAddAsOrganization(false, 1, searchString);
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getAllUserWhichCanBeAddAsOrganization(true, page, searchString);
      return page;
    });
  };

  const searchOrganizations = async (
    page: number,
    searchString: string,
    Organizations: any[]
  ) => {
    setIsLoading(true);

    try {
      const { data } = await apiLibrary.Communities.getallOrganization(
        searchString,
        page,
        values?.appName?.value
      );

      const entitieslist = getSortedDifference(
        data.organizations,
        Organizations
      );

      setEntitiesList(entitieslist);
      setTotalPages(data.to);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error("API call failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchOrganization = (searchString: string): void => {
    setPage((pre: number) => {
      searchOrganizations(1, searchString, values.organizationIds);
      return 1;
    });
  };

  const debouncedSearch = _.debounce(handleSearchOrganization, 300);

  return (
    <div className="bg-bgWhite w-[700px] rounded-lg dark:bg-secondaryLight">
      <div className="flex flex-col items-start self-stretch justify-between p-4 bg-white rounded-lg">
        <div className="w-full">
          <div className="flex items-center justify-items-center">
            <div className="flex flex-col items-start self-stretch justify-center w-full">
              <div className="flex items-center self-stretch justify-start gap-2">
                <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                  <p className="self-stretch w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                    Search For An Organization To Add To
                  </p>
                </div>
              </div>
            </div>
            <button
              title="close"
              type="button"
              onClick={handleClose}
              className="ml-8 cursor-pointer"
            >
              <XCloseIcon />
            </button>
          </div>
          <div className="flex w-full justify-start items-center gap-4 py-1.5">
            <div className="rounded-full">
              <img
                className="object-cover rounded-full w-9 h-9"
                src={values?.profileImage ? values?.profileImage : Placeholder}
              />
            </div>
            <div className="w-[95%] flex">
              <p className="max-h-32  break-all w-full text-[16px] text-left font-medium text-secondary dark:text-textMain">
                {values.name}{" "}
              </p>
            </div>
          </div>

          {selectedOrganization ? (
            <>
              <ProfileModal
                ConnectOrganization={(organization: any) => {
                  addExistingEntity(organization);
                  if (searchString !== "") {
                    setSearchString("");
                  }
                }}
                selectedOrganization={selectedOrganization}
                setSelectedOrganization={setSelectedOrganization}
                disconnectOrganization={(organization: any) => {
                  removeExistingEntity(organization);
                  if (searchString !== "") {
                    setSearchString("");
                  }
                }}
                addedOrganizationList={values.organizationIds}
              />
            </>
          ) : (
            <div className="flex flex-col items-start self-stretch justify-start bg-white">
              <div className="flex flex-col items-start self-stretch justify-start gap-3 pt-4 pb-2">
                <div className="w-full">
                  <TextInput
                    type="Search"
                    value={searchString}
                    placeholder="Search Name"
                    onChange={(e: any) => {
                      setSearchString(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[5px] rounded-lg">
                <div className="flex-grow h-px bg-lineMid" />
              </div>
              <div className="w-full overflow-hidden px-1 pb-4 h-[58vh]">
                <>
                  {values?.organizationIds?.length > 0 &&
                    searchString === "" && (
                      <div className="w-full">
                        <div className="flex justify-between p-3 space-x-4">
                          <div className="flex gap-2">
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V20L21 20C21.5523 20 22 20.4477 22 21C22 21.5522 21.5523 22 21 22H2.99999C2.4477 22 1.99999 21.5522 2 21C2.00001 20.4477 2.44773 20 3.00001 20L4 20V5ZM6 20H9V16C9 14.8954 9.89543 14 11 14H13C14.1046 14 15 14.8954 15 16V20H18V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44772 6 5V20ZM13 20V16H11V20H13ZM8 6.99998C8 6.44769 8.44772 5.99998 9 5.99998H10C10.5523 5.99998 11 6.44769 11 6.99998C11 7.55226 10.5523 7.99998 10 7.99998H9C8.44772 7.99998 8 7.55226 8 6.99998ZM13 6.99998C13 6.44769 13.4477 5.99998 14 5.99998H15C15.5523 5.99998 16 6.44769 16 6.99998C16 7.55226 15.5523 7.99998 15 7.99998H14C13.4477 7.99998 13 7.55226 13 6.99998ZM8 11C8 10.4477 8.44772 9.99998 9 9.99998H10C10.5523 9.99998 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H9C8.44772 12 8 11.5523 8 11ZM13 11C13 10.4477 13.4477 9.99998 14 9.99998H15C15.5523 9.99998 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H14C13.4477 12 13 11.5523 13 11Z"
                                fill="#2C3236"
                                className="dark:fill-caption"
                              />
                            </svg>
                            <p className="font-medium text-md text-textMid dark:text-textMain">
                              {values?.organizationIds?.length} Connected
                              Organizations
                            </p>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              removeAllEntities();
                              if (searchString !== "") {
                                setSearchString("");
                              }
                            }}
                          >
                            <p className="text-primary">Clear All</p>
                          </button>
                        </div>

                        <div className="max-h-[150px] overflow-y-auto">
                          {values?.organizationIds?.map(
                            (organization: any, index: number) => (
                              <ConnectedOrganizations
                                key={index}
                                organizationData={organization}
                                disconnectOrganization={() => {
                                  removeExistingEntity(organization);
                                  if (searchString !== "") {
                                    setSearchString("");
                                  }
                                }}
                                setSelectedOrganization={
                                  setSelectedOrganization
                                }
                              />
                            )
                          )}
                        </div>
                        <div className="flex justify-start items-center self-stretch gap-4 pt-2 pb-[8px] rounded-lg">
                          <div className="flex-grow h-px bg-lineMid" />
                        </div>
                      </div>
                    )}
                  {isLoading ? (
                    <>
                      <div className="loader min-h-[58vh]  justify-center items-center flex w-full">
                        <TailSpin
                          height="50"
                          width="50"
                          color="#005C89"
                          ariaLabel="tail-spin-loading"
                          radius="2"
                          wrapperStyle={{}}
                          wrapperClass="tailspin-loader"
                          visible={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-full">
                        <div className="flex justify-between p-3">
                          <div className="flex gap-2">
                            <div>
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="relative flex-grow-0 flex-shrink-0 w-6 h-6"
                                preserveAspectRatio="xMidYMid meet"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M4 5C4 3.34315 5.34315 2 7 2H17C18.6569 2 20 3.34315 20 5V20L21 20C21.5523 20 22 20.4477 22 21C22 21.5522 21.5523 22 21 22H2.99999C2.4477 22 1.99999 21.5522 2 21C2.00001 20.4477 2.44773 20 3.00001 20L4 20V5ZM6 20H9V16C9 14.8954 9.89543 14 11 14H13C14.1046 14 15 14.8954 15 16V20H18V5C18 4.44772 17.5523 4 17 4H7C6.44772 4 6 4.44772 6 5V20ZM13 20V16H11V20H13ZM8 6.99998C8 6.44769 8.44772 5.99998 9 5.99998H10C10.5523 5.99998 11 6.44769 11 6.99998C11 7.55226 10.5523 7.99998 10 7.99998H9C8.44772 7.99998 8 7.55226 8 6.99998ZM13 6.99998C13 6.44769 13.4477 5.99998 14 5.99998H15C15.5523 5.99998 16 6.44769 16 6.99998C16 7.55226 15.5523 7.99998 15 7.99998H14C13.4477 7.99998 13 7.55226 13 6.99998ZM8 11C8 10.4477 8.44772 9.99998 9 9.99998H10C10.5523 9.99998 11 10.4477 11 11C11 11.5523 10.5523 12 10 12H9C8.44772 12 8 11.5523 8 11ZM13 11C13 10.4477 13.4477 9.99998 14 9.99998H15C15.5523 9.99998 16 10.4477 16 11C16 11.5523 15.5523 12 15 12H14C13.4477 12 13 11.5523 13 11Z"
                                  fill="#2C3236"
                                  className="dark:fill-caption"
                                />
                              </svg>
                            </div>
                            <div className="w-full ">
                              <p className="font-medium text-md text-textMid dark:text-textMain">
                                {searchString !== ""
                                  ? ` ${entitiesList?.length} Search results`
                                  : `${entitiesList?.length} Organizations`}
                              </p>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => {
                              addAllEntities();
                              if (searchString !== "") {
                                setSearchString("");
                              }
                            }}
                          >
                            <p className="text-primary">Add All</p>
                          </button>
                        </div>
                        <InfiniteScroll
                          style={{height: '57vh'}}
                          dataLength={entitiesList.length}
                          next={handleLoadMore}
                          hasMore={hasMoreData}
                          height={170}
                          loader={
              <div className="flex items-center justify-center w-full loader">
                <TailSpin
                  height="30"
                  width="30"
                  color="#005C89"
                  ariaLabel="tail-spin-loading"
                  radius="2"
                  wrapperStyle={{}}
                  wrapperClass="tailspin-loader"
                  visible={true}
                />
              </div>
            }
                          pullDownToRefresh={true}
                          refreshFunction={() => {}}
                          pullDownToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8595; Pull down to refresh &#8595;
                            </h3>
                          }
                          releaseToRefreshContent={
                            <h3 style={{ textAlign: "center" }}>
                              &#8593; Release to refresh &#8593;
                            </h3>
                          }
                        >
                          {entitiesList?.map((organization, index) => (
                            <OrganizationList
                              key={index}
                              organizationData={organization}
                              ConnectOrganization={() => {
                                addExistingEntity(organization);
                                if (searchString !== "") {
                                  setSearchString("");
                                }
                                if (hasMoreData && entitiesList.length === 1) {
                                  handleLoadMore();
                                }
                              }}
                              setSelectedOrganization={setSelectedOrganization}
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 pt-3">
          <Button
            type="button"
            text="Cancel"
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="outlined"
            onClick={handleClose}
          />
          <Button
            type="button"
            onClick={handleSubmit}
            disabled={isFormLoading}
            text={isLastStep ? "Save" : "Next"}
            filledColor="primary"
            outlinedColor="primary"
            textColor="textWhite"
            className="px-5 py-2"
            width="35"
            height="13"
            fontStyle="font-semibold"
            variant="filled"
          />
        </div>
      </div>
    </div>
  );
};

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const difference = _.differenceBy(entitiesList, connectedEntitiesList, "id");
  const sortedList = _.sortBy(difference, [
    (organization: any) => organization.name.toLowerCase(),
  ]);

  return sortedList;
}
