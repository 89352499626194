import React from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "store";
import { IfetchSurveyJsFormDatails } from "store/formDetailsSurveyJsFields";
import * as surveyJsApi from "services/api/surveyJS";
import {
  updatePointPickers,
  updateShapePickers,
  updateDropPins,
  updateDrawPolygons,
  updateAddedHarvestPanelMapQuestions,
  updateAddedGeographicalPanelMapQuestions,
  updateAllCommunityLayers,
  updateAllCommunityLayersFetchingProgress,
  updateAllGlobalCommunityLayersFetchingProgress,
  updateAllCommunityPointsFetchingProgress,
  updateAllGlobalCommunityLayers,
  updateAllCommunityPoints,
  startRefreshingDynamicFormGeographiesAction,
  stopRefreshingDynamicFormGeographiesAction,
} from "store/surveyJS";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { panelsName } from "../../panelsTitle";
import { appendMapDataToPointsIfTheyDontHaveMapData } from "../../hooks/useFetchAllCommunityPoints";

const RefreshDynamicFormGeographyBtn = () => {
  const {
    shapePickers,
    pointPickers,
    drawPolygons,
    dropPins,
    harvestPanelMapQuestions,
    geographicalPanelMapQuestions,
    isRefreshingGeographies,
  } = useSelector((state: RootState) => state.surveyJS);
  const dispatch = useDispatch();
  const { formDetails, message, stateIs } = useSelector<
    RootState,
    IfetchSurveyJsFormDatails
  >((state) => state.formSurveyJsDetails);

  const fetchAllGeographies = async (formId: number) => {
    dispatch(startRefreshingDynamicFormGeographiesAction());
    try {
      // const randomPolygons = createArray(10, createRandomPolygon);
      // const randomPoints = createArray(10, createRandomPoint);

      const [allCommunityPoints, allCommunityLayers, allGlobalCommunityLayers] =
        await Promise.all([
          fetchDynamicFormLayers(formId, "point"),
          fetchDynamicFormLayers(formId, "shape"),
          fetchDynamicFormLayers(formId, "layers"),
        ]);

      // const updatePickers = (pickers: any[]) =>
      //   updateAllPickers(pickers, randomPolygons, randomPoints, randomPolygons);

      // const updatePanels = (panelQuestions: any) =>
      //   handleHarverstAndGeographicalInformationPanels(
      //     panelQuestions,
      //     randomPolygons,
      //     randomPoints,
      //     randomPolygons
      //   );

      const updatePickers = (pickers: any[]) =>
        updateAllPickers(
          pickers,
          allCommunityLayers,
          allCommunityPoints,
          allGlobalCommunityLayers
        );

      const updatePanels = (panelQuestions: any) =>
        handleHarverstAndGeographicalInformationPanels(
          panelQuestions,
          allCommunityLayers,
          allCommunityPoints,
          allGlobalCommunityLayers
        );

      const updatedShapePickers = updatePickers(shapePickers);
      const updatedPointPickers = updatePickers(pointPickers);
      const updatedDrawPolygons = updatePickers(drawPolygons);
      const updatedDropPins = updatePickers(dropPins);

      const updatedHarvestPanel = updatePanels(harvestPanelMapQuestions);
      const updatedGeographicalPanel = updatePanels(
        geographicalPanelMapQuestions
      );

      dispatch(updateShapePickers(updatedShapePickers));
      dispatch(updatePointPickers(updatedPointPickers));
      dispatch(updateDrawPolygons(updatedDrawPolygons));
      dispatch(updateDropPins(updatedDropPins));
      dispatch(updateAddedHarvestPanelMapQuestions(updatedHarvestPanel));
      dispatch(
        updateAddedGeographicalPanelMapQuestions(updatedGeographicalPanel)
      );
    } catch (error) {
      console.error("Error fetching geographies:", error);
    } finally {
      dispatch(stopRefreshingDynamicFormGeographiesAction());
    }
  };

  return (
    <div
      className={`relative flex items-center justify-start w-full gap-3 ml-5 mt-3`}
    >
      <button
        onClick={() => fetchAllGeographies(formDetails.id)}
        disabled={isRefreshingGeographies}
        className={`relative flex items-center justify-center w-auto gap-3 p-2 ${
          isRefreshingGeographies ? "cursor-not-allowed" : ""
        }`}
      >
        <svg
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`relative flex-grow-0 flex-shrink-0 w-6 h-6 ${
            isRefreshingGeographies ? "animate-spin" : ""
          }`}
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            d="M17.65 6.35001C16.8118 5.50428 15.7944 4.85762 14.6728 4.4578C13.5512 4.05798 12.3541 3.9152 11.17 4.04001C7.49999 4.41001 4.47999 7.39001 4.06999 11.06C3.51999 15.91 7.26999 20 12 20C13.5101 20 14.9892 19.5715 16.2655 18.7643C17.5418 17.9572 18.5628 16.8044 19.21 15.44C19.53 14.77 19.05 14 18.31 14C17.94 14 17.59 14.2 17.43 14.53C16.8494 15.779 15.8564 16.79 14.6179 17.3928C13.3795 17.9957 11.9712 18.1536 10.63 17.84C8.40999 17.35 6.61999 15.54 6.14999 13.32C5.95242 12.4423 5.95471 11.5313 6.15669 10.6546C6.35866 9.77787 6.75515 8.95776 7.31684 8.25493C7.87853 7.55211 8.59103 6.98454 9.40166 6.59423C10.2123 6.20392 11.1003 6.00084 12 6.00001C13.66 6.00001 15.14 6.69001 16.22 7.78001L14.71 9.29001C14.08 9.92001 14.52 11 15.41 11H19C19.55 11 20 10.55 20 10V6.41001C20 5.52001 18.92 5.07001 18.29 5.70001L17.65 6.35001Z"
            fill="#909090"
          />
        </svg>
        <p className="flex-grow-0 flex-shrink-0 text-base text-left capitalize text-[#909090]">
          {isRefreshingGeographies
            ? "Refreshing Map Features..."
            : "Refresh Map Features"}
        </p>
      </button>
    </div>
  );
};

export default RefreshDynamicFormGeographyBtn;

export async function fetchDynamicFormLayers(
  formId: number,
  layerType: string
) {
  if (!["shape", "point", "layers"].includes(layerType)) {
    console.error(`Invalid layerType: ${layerType}`);
    return [];
  }

  const progressActions: any = {
    shape: updateAllCommunityLayersFetchingProgress(true),
    point: updateAllCommunityPointsFetchingProgress(true),
    layers: updateAllGlobalCommunityLayersFetchingProgress(true),
  };

  const updateActions: any = {
    shape: updateAllCommunityLayers,
    point: updateAllCommunityPoints,
    layers: updateAllGlobalCommunityLayers,
  };

  store.dispatch(progressActions[layerType]);

  try {
    const response = await surveyJsApi.default.fetchLayers(formId, layerType);
    if (response && response.data) {
      if (layerType === "point") {
        const updatedPoints = await appendMapDataToPointsIfTheyDontHaveMapData(
          response.data
        );
        store.dispatch(updateActions[layerType](updatedPoints));
      }
      store.dispatch(updateActions[layerType](response.data));
      return response.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(
      `Error fetching ${layerType} layers for form ${formId}:`,
      error
    );
    return [];
  } finally {
    store.dispatch(progressActions[layerType]);
  }
}

export const pickerKeyMap: Record<
  string,
  {
    communityKey: string;
    globalKey: string;
    selectedItemsToHighlight: string;
    defaultLayers: string;
  }
> = {
  pointpicker: {
    communityKey: "allCommunityMarkers",
    globalKey: "allGlobalCommunityLayers",
    selectedItemsToHighlight: "selectedPointsIdToHighlight",
    defaultLayers: "selectedDefaultLayers",
  },
  shape_picker: {
    communityKey: "allCommunityLayers",
    globalKey: "allGlobalCommunityLayers",
    selectedItemsToHighlight: "selectedLayersIdToHighlight",
    defaultLayers: "selectedDefaultLayers",
  },
  drawpolygon: {
    communityKey: "allCommunityLayers",
    globalKey: "allGlobalCommunityLayers",
    selectedItemsToHighlight: "",
    defaultLayers: "selectedDefaultLayers",
  },
  droppin: {
    communityKey: "allCommunityLayers",
    globalKey: "allGlobalCommunityLayers",
    selectedItemsToHighlight: "",
    defaultLayers: "selectedDefaultLayers",
  },
};

export function updateSinglePicker(
  picker: any,
  allCommunityLayers: any[] = [],
  allCommunityMarkers: any[] = [],
  globalLayers: any[] = []
) {
  const keys = pickerKeyMap[picker.type];
  if (!keys) {
    console.warn(
      `Unknown picker type: ${picker.type}. Returning original picker.`
    );
    return picker;
  }

  const { communityKey, globalKey, selectedItemsToHighlight, defaultLayers } =
    keys;

  const allCommunityItemsForPicker = picker[communityKey] || [];
  const allGlobalCommunityLayersForPicker = picker[globalKey] || [];
  const selectedItems = picker[selectedItemsToHighlight] || [];
  const selectedDefaultLayers = picker[defaultLayers] ?? [];

  // Determine the correct items for 'community' and 'global' layers.
  const allCommunityItems =
    picker.type === "pointpicker" ? allCommunityMarkers : allCommunityLayers;

  // Function to update items with ids
  const addItemIds = (items: any[], idPrefix: string) =>
    items.map((item: any) => ({ ...item, id: `${idPrefix}-${item.id}` }));

  // Generate updated items with ids
  const updatedCommunityItemsWithId = addItemIds(
    allCommunityItems,
    picker.id ?? picker._id
  );


  // // Combine and remove duplicates from global layers
  // const combinedGlobalLayers = [
  //   ...allGlobalCommunityLayersForPicker,
  //   ...globalLayers,
  // ];
  // const updatedGlobalCommunityLayers = _.uniqBy(combinedGlobalLayers, "id");

  // Function to filter selected items
  const filterSelectedItems = (selectedItems: any[], allItemsWithId: any[]) =>
    selectedItems.filter((selectedItem) =>
      allItemsWithId.some((item) => item.id === selectedItem.id)
    );

  // Filter selected items and default layers

  const filtersSelectedItems = (
    selectedItems: any[] = [],
    allItemsWithId: any[] = []
  ) => {
    // Filter and map over selectedItems to include only those found in allItemsWithId
    return selectedItems.reduce((updatedItems, selectedItem) => {
      // Find the matching item in allItemsWithId
      const match = allItemsWithId.find(
        (item: any) => item.id === selectedItem.id
      );
      // If a match is found, merge and add to the updatedItems
      if (match) {
        updatedItems.push({ ...selectedItem, ...match });
      }
      // Return the accumulating array of updated items
      return updatedItems;
    }, []);
  };

  const filteredSelectedItems =
    updatedCommunityItemsWithId.length > 0
      ? filtersSelectedItems(selectedItems, updatedCommunityItemsWithId)
      : [];
  const filteredSelectedLayers =
    globalLayers.length > 0
      ? filtersSelectedItems(selectedDefaultLayers, globalLayers)
      : [];

  // Create updated picker object
  const updatedPicker = { ...picker };

  updatedPicker[communityKey] = updatedCommunityItemsWithId;
  // Update global layers only if necessary
  updatedPicker[globalKey] = globalLayers;

  // Update selected items only if necessary
  updatedPicker[selectedItemsToHighlight] = filteredSelectedItems;
  updatedPicker[defaultLayers] = filteredSelectedLayers;

  return updatedPicker;
}

function updateAllPickers(
  pickers: any[],
  allCommunityLayers: any[] = [],
  allCommunityMarkers: any[] = [],
  globalLayers: any[] = []
) {
  return pickers.map((picker) =>
    updateSinglePicker(
      picker,
      allCommunityLayers,
      allCommunityMarkers,
      globalLayers
    )
  );
}

export function handleHarverstAndGeographicalInformationPanels(
  panel: any,
  allCommunityLayers: any[] = [],
  allCommunityMarkers: any[] = [],
  globalLayers: any[] = []
): any {
  if (!panel || !panel.type) {
    console.warn("Invalid panel data provided.");
    return panel;
  }

  let questionInstance: any;

  switch (panel.type) {
    case panelsName.GEOGRAPHICAL_INFORMATION:
      questionInstance = {
        ...panel,
        regionTypeShapePicker: updateSinglePicker(
          panel.regionTypeShapePicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
        viewTypeShapePicker: updateSinglePicker(
          panel.viewTypeShapePicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
        pointPicker: updateSinglePicker(
          panel.pointPicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
      };
      break;

    case panelsName.HARVEST:
      questionInstance = {
        ...panel,
        retrievalShapePicker: updateSinglePicker(
          panel.retrievalShapePicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
        retrievalPointPicker: updateSinglePicker(
          panel.retrievalPointPicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
        struckShapePicker: updateSinglePicker(
          panel.struckShapePicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
        struckPointPicker: updateSinglePicker(
          panel.struckPointPicker,
          allCommunityLayers,
          allCommunityMarkers,
          globalLayers
        ),
      };
      break;

    default:
      console.warn(`Unhandled panel type: ${panel.type}`);
      questionInstance = panel;
  }

  return questionInstance;
}


export function filterSelectedItems  (
  selectedItems: any[] = [],
  allItemsWithId: any[] = []
) {
  // Filter and map over selectedItems to include only those found in allItemsWithId
  return selectedItems.reduce((updatedItems, selectedItem) => {
    // Find the matching item in allItemsWithId
    const match = allItemsWithId.find(
      (item: any) => item.id === selectedItem.id
    );
    // If a match is found, merge and add to the updatedItems
    if (match) {
      updatedItems.push({
        ...selectedItem,
        ...match,
        uniqueProps: {
          ...selectedItem.uniqueProps,
          ...selectedItem.uniqueProps,
        },
      });
    }
    // Return the accumulating array of updated items
    return updatedItems;
  }, []);
};

function getRandomCoordinates(): [number, number] {
  const longitude = parseFloat((Math.random() * 360 - 180).toFixed(6));
  const latitude = parseFloat((Math.random() * 180 - 90).toFixed(6));
  return [longitude, latitude];
}

function getRandomPolygonCoordinates(): [number, number][][] {
  const coordinates: [number, number][] = [];
  const numPoints = Math.floor(Math.random() * 5) + 3;
  for (let i = 0; i < numPoints; i++) {
    coordinates.push(getRandomCoordinates());
  }
  coordinates.push(coordinates[0]);
  return [coordinates];
}

function getRandomColor(): string {
  const hex = (
    "000000" + Math.floor(Math.random() * 16777215).toString(16)
  ).slice(-6);
  return `#${hex}`;
}

function getRandomImageUrl(): string {
  const width = Math.floor(Math.random() * 401) + 100;
  const height = Math.floor(Math.random() * 401) + 100;
  return `https://picsum.photos//${width}/${height}`;
}

function createRandomPolygon() {
  const id = Math.floor(Math.random() * 10000);
  const shapeTypes = ["trails", "water", "land", "boundary"];
  const shapeType = shapeTypes[Math.floor(Math.random() * shapeTypes.length)];

  return {
    borderColor: getRandomColor(),
    color: getRandomColor(),
    geoFilePath: null,
    id: id,
    legendFileId: null,
    mapData: {
      features: [
        {
          geometry: {
            coordinates: getRandomPolygonCoordinates(),
            type: "Polygon",
          },
          properties: {
            name: `Random Polygon ${id}`,
          },
          type: "Feature",
        },
      ],
      type: "FeatureCollection",
    },
    markerGroup: null,
    markerStyle: null,
    name: `Random Shape ${id}`,
    shapeType: shapeType,
    type: "shape",
    uniqueProps: {},
    vectorLayers: null,
  };
}

function createRandomPoint() {
  const id = Math.floor(Math.random() * 10000);
  const groupId = Math.floor(Math.random() * 100) + 1;
  const markerStyleId = Math.floor(Math.random() * 1000);

  return {
    borderColor: null,
    color: null,
    geoFilePath: null,
    id: id,
    legendFileId: null,
    mapData: {
      features: [
        {
          geometry: {
            coordinates: getRandomCoordinates(),
            type: "Point",
          },
          properties: {
            name: `Random Point ${id}`,
          },
          type: "Feature",
        },
      ],
      type: "FeatureCollection",
    },
    markerGroup: {
      id: groupId,
      name: `Group ${groupId}`,
    },
    markerStyle: {
      groupId: groupId,
      id: markerStyleId,
      markerStyleId: `${id}-${markerStyleId}`,
      styleImage: getRandomImageUrl(), // Random image URL
    },
    name: `Random Point ${id}`,
    shapeType: null,
    type: "point",
    uniqueProps: {},
    vectorLayers: null,
  };
}

function createArray<T>(count: number, createFn: () => T): T[] {
  const result: T[] = [];
  for (let i = 0; i < count; i++) {
    result.push(createFn());
  }
  return result;
}



