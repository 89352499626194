import React, { useEffect, useRef, useState } from 'react';
import UsersIcon from 'assets/icons/HeroIcons/UsersIcon';
import Button from 'view/components/Button';
import TextInput from 'view/components/InputField';
import apiLibrary from 'services/api';
import XCloseIcon from 'assets/icons/HeroIcons/XCloseIcon';
import Placeholder from 'assets/images/Placeholders/avatar_communityProfile_placeholder.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TailSpin } from 'react-loader-spinner';

import { Users } from './Users';
import Admins from './Admins';
import _ from 'lodash';
import { ProfileModal } from './ProfileModal';
import { FormikProps, FormikValues } from 'formik';
import { Toasts } from 'view/components/Toasts';
import { createDebouncedFunction } from 'utils/debouncer';
import { MaterialUiSelect } from 'view/components/Select';

interface AddAdminProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const AddAdmin: React.FC<AddAdminProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  const [searchString, setSearchString] = useState('');
  const [entitiesList, setEntitiesList] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [page, setPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);

  const customSort = (entities: any): any => {
    return entities.sort((a: any, b: any) => a.name.localeCompare(b.name));
  };

  const updateAdminIds = (values: any, entity: any) => {
    const adminIds = values?.adminIds ?? [];
    const isEntityAlreadyConnected = adminIds.some(
      (item: any) => item.id === entity.id
    );

    if (!isEntityAlreadyConnected) {
      const updatedList = [...adminIds, entity];
      return customSort(updatedList);
    }

    return adminIds;
  };

  const addExistingEntity = (entity: any) => {
    setEntitiesList((prev) => prev.filter((item) => item.id !== entity.id));
    const updatedList = updateAdminIds(values, entity);
    if (searchString !== '') {
      searchUserWhichCanBeAddAsAdmin(1, '', updatedList);
    }
    setFieldValue('adminIds', updatedList);
  };

  const removeExistingEntity = (entity: any): void => {
    setEntitiesList((prevEntitiesList) => {
      const isEntityPresent = prevEntitiesList.some(
        (item) => item.id === entity.id
      );

      if (!isEntityPresent) {
        const updatedEntitiesList = [...prevEntitiesList, entity];
        return customSort(updatedEntitiesList);
      } else {
        return prevEntitiesList;
      }
    });

    function updateConnectedEntitiesList() {
      const updatedConnectedEntitiesList = values?.adminIds?.filter(
        (item: any) => item.id !== entity.id
      );

      return customSort(updatedConnectedEntitiesList);
    }

    const newList = updateConnectedEntitiesList();
    if (searchString !== '') {
      searchUserWhichCanBeAddAsAdmin(1, '', newList);
    }

    setFieldValue('adminIds', newList);
  };
  const removeAllEntities = (): void => {
    setEntitiesList((prevEntitiesList) => {
      const updatedEntitiesList = [
        ...prevEntitiesList,
        ...values?.adminIds?.filter(
          (connectedEntity: any) =>
            !prevEntitiesList.some((entity) => entity.id === connectedEntity.id)
        ),
      ];
      return customSort(updatedEntitiesList);
    });

    setFieldValue('adminIds', []);
  };

  const addAllEntities = (): void => {
    const updatedAdminIds = () => {
      const updatedEntitiesList = [
        ...values?.adminIds,
        ...entitiesList.filter(
          (newEntity) =>
            !values?.adminIds?.some(
              (prevEntity: any) => prevEntity.id === newEntity.id
            )
        ),
      ];
      return customSort(updatedEntitiesList);
    };

    if (searchString !== '') {
      searchUserWhichCanBeAddAsAdmin(1, '', updatedAdminIds());
    }
    setFieldValue('adminIds', updatedAdminIds());
    setEntitiesList([]);
  };

  const getAllUserWhichCanBeAddAsAdmin = async (
    isLoadMore: boolean,
    page: number,
    searchString: string
  ) => {
    if (!isLoadMore) {
      setIsLoading(true);
    }

    try {
      const { data } =
        await apiLibrary.Communities.getAllUserWhichCanBeAddAsAdmin(
          page,
          searchString,
          values?.appName?.value
        );

      const communityAdmins = values.adminIds ?? [];

      const entitieslist = getSortedDifference(
        data.users,
        isLoadMore ? values.adminIds : communityAdmins
      );

      setEntitiesList(
        isLoadMore ? (prevList) => [...prevList, ...entitieslist] : entitieslist
      );

      setTotalPages(data.totalPages);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error('API call failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserWhichCanBeAddAsAdmin(false, 1, searchString);
  }, []);

  const handleLoadMore = () => {
    setPage((prevPage) => {
      const page = prevPage + 1;
      getAllUserWhichCanBeAddAsAdmin(true, page, searchString);
      return page;
    });
  };

  const searchUserWhichCanBeAddAsAdmin = async (
    page: number,
    searchString: string,
    admins: any[]
  ) => {
    setIsLoading(true);

    try {
      const { data } =
        await apiLibrary.Communities.getAllUserWhichCanBeAddAsAdmin(
          page,
          searchString,
          values?.appName?.value
        );

      const entitieslist = getSortedDifference(data.users, admins);

      setEntitiesList(entitieslist);
      setTotalPages(data.to);
      setHasMoreData(data.totalPages > page);
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
      console.error('API call failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchUsersWhichCanBeAddAsAdmin = (
    searchString: string
  ): void => {
    setPage((pre: number) => {
      searchUserWhichCanBeAddAsAdmin(1, searchString, values.adminIds);
      return 1;
    });
  };

  const debouncedSearch = createDebouncedFunction(
    handleSearchUsersWhichCanBeAddAsAdmin,
    1000
  );

  return (
    <div className="bg-bgWhite w-[700px] rounded-lg dark:bg-secondaryLight">
      <div className="flex flex-col items-start self-stretch justify-between p-4 bg-white rounded-lg h-full">
        <div className="w-full">
          <div className="flex items-center justify-items-center">
            <div className="flex flex-col items-start self-stretch justify-center w-full">
              <div className="flex items-center self-stretch justify-start gap-2">
                <div className="flex flex-col items-start justify-center flex-grow py-1 ">
                  <p className="self-stretch w-full text-lg font-semibold text-left text-secondaryMid dark:text-textMain">
                    Search For An Admin To Add To
                  </p>
                </div>
              </div>
            </div>
            <button
              title='close'
              type='button'
              onClick={handleClose}
              className='ml-8 cursor-pointer'
            >
              <XCloseIcon />
            </button>
          </div>
          <div className='flex w-full justify-start items-center gap-4 py-1.5'>
            <div className='rounded-full'>
              <img
                className='object-cover rounded-full w-9 h-9'
                src={values?.profileImage ? values?.profileImage : Placeholder}
              />
            </div>
            <div className='w-[95%] flex'>
              <p className='max-h-32  break-all w-full text-[16px] text-left font-medium text-secondary dark:text-textMain'>
                {values.name}{' '}
              </p>
            </div>
          </div>

          {selectedUser ? (
            <>
              <ProfileModal
                addAdmin={(user: any) => {
                  addExistingEntity(user);
                  if (searchString !== '') {
                    setSearchString('');
                  }
                }}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                unAssignAdmin={(admin: any) => {
                  removeExistingEntity(admin);
                  if (searchString !== '') {
                    setSearchString('');
                  }
                }}
                addedAdminsList={values.adminIds}
              />
            </>
          ) : (
            <div className='flex flex-col items-start self-stretch justify-start bg-white'>
              <div className='flex flex-col items-start self-stretch justify-start gap-3 pt-4 pb-2'>
                <div className='w-full'>
                  <TextInput
                    type='Search'
                    value={searchString}
                    placeholder='Search Name'
                    onChange={(e: any) => {
                      setSearchString(e.target.value);
                      debouncedSearch(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className='flex justify-start items-center self-stretch gap-4 pt-2 pb-[5px] rounded-lg'>
                <div className='flex-grow h-px bg-lineMid' />
              </div>
              {isLoading ? (
                <div className="loader min-h-[58vh]  justify-center items-center flex w-full">
                  <TailSpin
                    height='50'
                    width='50'
                    color='#005C89'
                    ariaLabel='tail-spin-loading'
                    radius='2'
                    wrapperStyle={{}}
                    wrapperClass='tailspin-loader'
                    visible={true}
                  />
                </div>
              ) : (
                <div className="w-full overflow-hidden px-1 pb-4 h-[58vh] flex flex-col">
                  <>
                    {values.adminIds?.length > 0 && searchString === '' && (
                      <div className='w-full'>
                        <div className='flex justify-between p-3 space-x-4'>
                          <div className='flex gap-2'>
                            <UsersIcon />
                            <p className='font-medium text-md text-textMid dark:text-textMain'>
                              {values.adminIds?.length} Assigned Admins
                            </p>
                          </div>
                          <button
                            type='button'
                            onClick={() => removeAllEntities()}
                          >
                            <p className='text-primary'>Clear All</p>
                          </button>
                        </div>

                        <div className="max-h-[150px] overflow-y-auto">
                          {values.adminIds?.map((admin: any, index: number) => (
                            <Admins
                              key={index}
                              userData={admin}
                              unAssignAdmin={() => removeExistingEntity(admin)}
                              setSelectedUser={setSelectedUser}
                            />
                          ))}
                        </div>
                        <div className='flex justify-start items-center self-stretch gap-4 pt-2 pb-[8px] rounded-lg'>
                          <div className='flex-grow h-px bg-lineMid' />
                        </div>
                      </div>
                    )}

                    <div className='w-full overflow-hidden flex flex-col flex-1'>
                      <div className='flex justify-between p-3'>
                        <div className='flex gap-2'>
                          <div>
                            <UsersIcon />
                          </div>
                          <div className='flex w-full gap-1'>
                            <p className='font-medium text-md text-textMid dark:text-textMain'>
                              {entitiesList?.length}
                            </p>
                            <p className='font-medium text-md text-textMid dark:text-textMain'>
                              {searchString !== '' ? 'Search results' : 'Users'}
                            </p>
                          </div>
                        </div>
                        <button
                          type='button'
                          onClick={() => {
                            addAllEntities();
                            if (searchString !== '') {
                              setSearchString('');
                            }
                          }}
                        >
                          <p className='text-primary'>Add All</p>
                        </button>
                      </div>

                      <div id='usersDiv' className={`overflow-auto`}>
                        <InfiniteScroll
                          dataLength={entitiesList.length}
                          next={handleLoadMore}
                          hasMore={hasMoreData}
                          scrollableTarget='usersDiv'
                          loader={
                            <div className='flex items-center justify-center w-full loader'>
                              <TailSpin
                                height='30'
                                width='30'
                                color='#005C89'
                                ariaLabel='tail-spin-loading'
                                radius='2'
                                wrapperStyle={{}}
                                wrapperClass='tailspin-loader'
                                visible={true}
                              />
                            </div>
                          }
                          pullDownToRefresh={true}
                          refreshFunction={() => {}}
                          pullDownToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                              &#8595; Pull down to refresh &#8595;
                            </h3>
                          }
                          releaseToRefreshContent={
                            <h3 style={{ textAlign: 'center' }}>
                              &#8593; Release to refresh &#8593;
                            </h3>
                          }
                        >
                          {entitiesList?.map((user, index) => (
                            <Users
                              key={index}
                              userData={user}
                              addAdmin={() => {
                                addExistingEntity(user);
                                if (searchString !== '') {
                                  setSearchString('');
                                }
                                if (hasMoreData && entitiesList.length === 1) {
                                  handleLoadMore();
                                }
                              }}
                              setSelectedUser={setSelectedUser}
                            />
                          ))}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2 pt-3">
          <Button
            type='button'
            text='Cancel'
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='w-24 h-11'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='outlined'
            onClick={handleClose}
          />
          <Button
            type='button'
            onClick={handleSubmit}
            disabled={isFormLoading}
            text={isLastStep ? 'Save' : 'Next'}
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='w-24 h-11'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='filled'
          />
        </div>
      </div>
    </div>
  );
};

function getSortedDifference(
  entitiesList: any,
  connectedEntitiesList: any
): any[] {
  const reStructuredEntitiesList = reStructureTheEntitiesList(entitiesList);
  const reStructuredConnectedEntitiesList = reStructureTheEntitiesList(
    connectedEntitiesList
  );

  const difference = _.differenceBy(
    reStructuredEntitiesList,
    reStructuredConnectedEntitiesList,
    'id'
  );
  return difference;
}

function getSortedEntities(entitiesList: any) {
  return _.sortBy(entitiesList, 'name');
}

function reStructureTheEntitiesList(entitiesList: any): any[] {
  const reStructuredList = entitiesList.map((user: any) => {
    const { ...rest } = user;
    return {
      ...rest,
      name: `${user.firstName} ${user.lastName}`,
      profileImage: user.profileImage,
    };
  });
  const sortedList = _.sortBy(reStructuredList, [
    (user) => user.name.toLowerCase(),
  ]);

  return sortedList;
}
